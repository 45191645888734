import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Layout from "../components/layout"

import Hero from "../components/hero"
import Apps from "../components/apps"
import BlogPreview from "../components/blog-preview"

export default () => (
  <Layout>
    <GatsbySeo
      title="Contact"
      bodyAttributes={{
        class: "bg-black",
        style: {},
      }}
    />

    <Hero />

    <div className="container mx-auto pb-20">
      <div className="text-center text-4xl w-full my-20 hover:scale-110  transform transition duration-200">
        <a href="mailto:hi@thefunbots.com hover:no-underline">
          <span className="speech-bubble ">
            <span className="text-bluex-300">hi</span>
            <span className="text-bluex-800 opacity-25">@</span>
            <span className="text-bluex-300">thefunbots</span>
            <span className="text-bluex-800 opacity-25">.</span>
            <span className="text-bluex-300">com</span>
          </span>
        </a>
      </div>
    </div>
  </Layout>
)
